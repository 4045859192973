require("@rails/ujs").start()
require("turbolinks").start()

import 'controllers'

import 'vendor/bootstrap'
import 'vendor/plausible'
import 'vendor/plugins'
import 'vendor/drew'
import 'vendor/masonry'

// import 'vendor/gtag'
// import 'vendor/fb_pixel'
// import 'vendor/tawk_to'
