import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "content" ]
  static classes = [ "hidden" ]

  toggle(event) {
    event.preventDefault()
    event.target.classList.toggle(this.hiddenClass)
    this.contentTarget.classList.toggle(this.hiddenClass)
  }
}
