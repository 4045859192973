import Masonry from 'masonry-layout'

document.addEventListener("turbolinks:load", function() {
  let elem = document.getElementById('portfolio-row')

  if(elem) {
    let masonry = new Masonry(elem, { itemSelector: '.happy-dog' })

    document.addEventListener('lazyloaded', function(e){
      masonry.layout()
    })
  }
});
