import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "planId" ]

  change(event) {
    let select = event.target
    let option = select[select.selectedIndex]
    this.planIdTarget.value = option.value
  }
}
