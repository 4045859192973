import { Controller } from "stimulus"
import Cleave from 'cleave.js'

export default class extends Controller {
  connect() {
    var cleave = new Cleave(this.element, {
      date: true,
      dateMin: '2000-01-01',
      datePattern: ['d', 'm', 'Y'],
      delimiter: '.'
    })
  }
}
