import { Controller } from "stimulus"

export default class extends Controller {
  static values = { planId: Number, prices: Object }
  static targets = [ "totalPrice", "promoCode" ]

  updatePlanId(event) {
    let select = event.target
    let option = select[select.selectedIndex]
    this.planIdValue = option.value
  }

  planIdValueChanged() {
    this.updatePrice()
    this.togglePromo()
  }

  pricesValueChanged() {
    this.updatePrice()
  }

  updatePrice() {
    this.totalPriceTarget.textContent = this.pricesValue[this.planIdValue]
  }

  togglePromo() {
    if(this.planIdValue == 4) {
      this.promoCodeTarget.hidden = true
    } else {
      this.promoCodeTarget.hidden = false
    }
  }
}
